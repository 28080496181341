@mixin spacer-generator($property, $prefix) {
  $spacers: (
    'none': 0,
    'xs': var(--spacer-xs),
    'sm': var(--spacer-sm),
    'md': var(--spacer-md),
    'lg': var(--spacer-lg),
    'xl': var(--spacer-xl),
    'xxl': var(--spacer-xxl)
  );

  @each $name, $size in $spacers {
    .#{$prefix}-#{$name} {
      #{$property}: $size;
    }

    .#{$prefix}-y-#{$name} {
      #{$property}-top: $size;
      #{$property}-bottom: $size;
    }

    .#{$prefix}-x-#{$name} {
      #{$property}-left: $size;
      #{$property}-right: $size;
    }

    .#{$prefix}-t-#{$name} {
      #{$property}-top: $size;
    }

    .#{$prefix}-b-#{$name} {
      #{$property}-bottom: $size;
    }

    .#{$prefix}-l-#{$name} {
      #{$property}-left: $size;
    }

    .#{$prefix}-r-#{$name} {
      #{$property}-right: $size;
    }
  }
}

@mixin setup() {
  @include spacer-generator(margin, m);
  @include spacer-generator(padding, p);
}
