@use '../mixins/forms';
@use 'sass:math';

$form-input-radio-size: 16px;

@mixin make-form-input-checkbox-and-radio() {
  input[type='radio'],
  input[type='checkbox'] {
    @include forms.make-disabled('&', '& + span:before');
    @include forms.make-disabled('&:checked', '& + span:before');

    display: none;

    & + span {
      margin-left: calc($form-input-radio-size * 2);
      cursor: pointer;
      color: var(--text-secondary);

      &::before {
        content: ' ';
        position: absolute;
        left: 0;
        top: 3px;
        display: inline-block;
        border-radius: var(--radius-xs);
        width: $form-input-radio-size;
        height: $form-input-radio-size;
        border: 1px solid var(--border-neutral-contrast);
      }

      &::after {
        position: absolute;
        display: inline-block;
        left: calc($form-input-radio-size / 4);
        top: 7px;
        width: calc($form-input-radio-size * 0.5);
        height: calc($form-input-radio-size * 0.5);
      }

      &:hover {
        &::before {
          border-color: var(--border-brand);
          box-shadow: 0 0 0 2px var(--background-brand-fill), 0 0 0 4px var(--border-brand-overlay);
          transition: var(--global-transition);
        }
      }

      &.disabled {
        color: var(--text-neutral-fill);
        cursor: not-allowed;
        pointer-events: none;
      }
    }

    &.disabled,
    &:disabled,
    &[readonly] {
      & + span {
        color: var(--text-neutral-fill);

        &:hover {
          &::before {
            border-color: var(--border-neutral);
          }
        }
      }
    }

    &:checked + span {
      &::before {
        background-color: var(--background-primary);
        border-color: var(--border-brand);
      }

      &::after {
        content: ' ';
        background-color: var(--background-brand);
      }
    }

    & + .char {
      color: var(--text-brand);
    }
  }

  input[type='radio'] {
    @include forms.make-disabled('&:checked', '& + span:after', var(--text-neutral-fill));

    & + span {
      &::before,
      &::after {
        border-radius: 100%;
      }
    }
  }

  input[type='checkbox'] {
    @include forms.make-disabled('&:checked', '& + span:after', transparent, var(--text-neutral-fill));

    &:checked + span {
      &::before {
        background-color: var(--background-brand);
        border-color: var(--border-brand);
      }

      &::after {
        background-color: transparent;
        width: 6px;
        border-width: 0 2px 2px 0;
        border-style: solid;
        border-color: var(--background-primary);
        transform: translate(1px, -1px) rotate(45deg);
      }
    }
  }

  .form-group {
    &.ng-touched.ng-invalid,
    &.ng-invalid.ng-touched {
      input[type='radio'],
      input[type='checkbox'] {
        & + span {
          &::before {
            box-shadow: 0 0 0 1px var(--text-danger);
          }
        }
      }
    }
  }
}
