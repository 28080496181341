@mixin setup() {
  .h-100 {
    height: 100%;
  }

  .w-100 {
    width: 100%;
  }

  .truncate-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
  }

  .truncate-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .truncate-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .flex-grow-1 {
    flex-grow: 1 !important;
  }

  .relative {
    position: relative;
  }

  .flex-height {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .no-select {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  .no-wrap {
    white-space: nowrap;
  }
}