@mixin badge($bg-color, $text-color, $border-color) {
  display: inline-block;
  height: fit-content;
  background-color: $bg-color;
  text-align: center;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-sm);
  color: $text-color;
  border: 1px solid $border-color;
  border-radius: var(--radius-sm);
  padding: var(--spacer-xs) var(--spacer-xs);
  min-width: 48px;
}

@mixin create-badge($class, $bg-color, $text-color, $border-color) {
  .#{$class} {
    @include badge($bg-color, $text-color, $border-color);
  }
}

@mixin create-badge-sm($class, $bg-color, $text-color, $border-color) {
  .#{$class} {
    @include badge($bg-color, $text-color, $border-color);

    min-width: auto;
    border-radius: var(--radius-xs);
    text-transform: uppercase;
    padding: calc(var(--spacer-xs) / 2) calc(var(--spacer-xs) / 2);
  }
}

@mixin setup() {
  @include create-badge('badge-primary', var(--background-brand-overlay), var(--text-brand), var(--border-brand-overlay));
  @include create-badge('badge-primary-fill', var(--background-brand), var(--text-primary), var(--border-brand-contrast));
  @include create-badge('badge-secondary', var(--background-neutral-muted), var(--text-neutral-contrast), var(--border-neutral-muted));
  @include create-badge('badge-secondary-fill', var(--background-neutral-fill), var(--text-secondary), var(--border-neutral-contrast));
  @include create-badge('badge-danger', var(--background-danger-overlay), var(--text-danger-contrast), var(--border-danger-overlay));
  @include create-badge('badge-danger-fill', var(--background-danger), var(--text-primary), var(--border-danger-contrast));
  @include create-badge('badge-success', var(--background-success-overlay), var(--text-success-contrast), var(--border-success-overlay));
  @include create-badge('badge-success-fill', var(--background-success), var(--text-secondary), var(--border-success-contrast));
  @include create-badge('badge-warning', var(--background-warning-overlay), var(--text-warning-contrast), var(--border-warning-overlay));
  @include create-badge('badge-warning-fill', var(--background-warning), var(--text-secondary), var(--border-warning-contrast));
  @include create-badge('badge-info', var(--background-info-overlay), var(--text-info-contrast), var(--border-info-overlay));
  @include create-badge('badge-info-fill', var(--background-info), var(--text-primary), var(--border-info-contrast));
  @include create-badge('badge-royal', var(--background-royal-overlay), var(--text-royal-contrast), var(--border-royal-overlay));
  @include create-badge('badge-royal-fill', var(--background-royal), var(--text-primary), var(--border-royal-contrast));
  @include create-badge('badge-clear', var(--background-clear-overlay), var(--text-clear-contrast), var(--border-clear-overlay));
  @include create-badge('badge-clear-fill', var(--background-clear), var(--text-secondary), var(--border-clear-contrast));
  @include create-badge-sm('badge-primary-sm', var(--background-brand-overlay), var(--text-brand), var(--border-brand-overlay));
  @include create-badge-sm('badge-secondary-sm', var(--background-neutral-muted), var(--text-neutral-contrast),	var(--border-neutral-muted));
  @include create-badge-sm('badge-danger-sm', var(--background-danger-overlay), var(--text-danger-contrast), var(--border-danger-overlay));
  @include create-badge-sm('badge-success-sm', var(--background-success-overlay),	var(--text-success-contrast),	var(--border-success-overlay));
  @include create-badge-sm('badge-warning-sm', var(--background-warning-overlay), var(--text-warning-contrast), var(--border-warning-overlay));
  @include create-badge-sm('badge-info-sm', var(--background-info-overlay), var(--text-info-contrast), var(--border-info-overlay));
  @include create-badge-sm('badge-royal-sm', var(--background-royal-overlay), var(--text-royal-contrast), var(--border-royal-overlay));
  @include create-badge-sm('badge-clear-sm', var(--background-clear), var(--text-secondary), var(--border-success-overlay));
  @include create-badge-sm('badge-secondary-sm-b', transparent, var(--text-neutral-contrast), var(--border-neutral-contrast));
  @include create-badge-sm('badge-royal-sm-b', transparent, var(--text-royal-contrast), var(--border-royal-contrast));
}
