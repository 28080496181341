@mixin setup() {
  ::-webkit-scrollbar {
    height: var(--spacer-lg);
    width: var(--spacer-sm);
  }

  ::-webkit-scrollbar-track {
    background-color: var(--background-neutral-muted);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--background-neutral-contrast);
    border-radius: var(--radius-sm);
    border: 1px solid transparent;
    cursor: grab;

    &:hover {
      cursor: grabbing;
    }
  }
}
