@use '../variables';
@use '../mixins/general';

@mixin buildTypographyUtility($suffix, $size) {
  .text-#{$suffix} {
    font-size: $size;
  }
}

@mixin make-theme-typography() {
  b,
  strong,
  .text-bold {
    font-weight: var(--font-weight-bold);
  }

  .text-bold-normal {
    font-weight: normal;
  }

  i,
  .text-italic {
    font-weight: normal;
    font-style: italic;
  }

  .text-right {
    text-align: right;
  }

  .text-justify {
    text-align: justify;
  }

  .text-center {
    text-align: center;
  }

  .paragraph {
    padding: var(--spacer-lg);
  }

  .pointer {
    cursor: pointer;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: var(--default-line-height);
  }

  h1,
  .h1 {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
  }

  h2,
  .h2 {
    font-size: var(--font-size-sm);
    font-weight: var(--default-font-weight);
  }

  h3,
  .h3 {
    font-size: var(--default-font-size);
    font-weight: var(--font-weight-medium);
  }

  h4,
  .h4 {
    font-size: var(--default-font-size);
    font-weight: var(--default-font-weight);
  }

  h5,
  .h5 {
    font-size: var(--font-size-xs);
    font-weight: var(--default-font-weight);
  }

  h6,
  .h6 {
    font-size: var(--font-size-xs);
  }

  .bold {
    font-weight: var(--font-weight-bold);
  }

  a {
    color: var(--text-info-contrast);
    text-decoration: none;

    &:hover {
      color: var(--text-info-contrast);
      text-decoration: underline;
    }

    &:active {
      color: var(--text-info-contrast);
      text-decoration: underline;
    }

    a:visited {
      color: var(--text-info-contrast);
      text-decoration: none;
    }

    &.disabled {
      color: var(--text-neutral-fill);
      cursor: not-allowed;
      pointer-events: none;

      &:hover {
        color: var(--text-neutral-fill);
        cursor: not-allowed;
        pointer-events: none;
        text-decoration: none;
      }
    }
  }

  .disabled-text {
    color: var(--text-neutral-fill);
  }

  .highlight-text {
    background-color: var(--background-neutral-overlay);
  }

  .text-break {
    word-wrap: break-word;
  }

  @include buildTypographyUtility(xs, var(--font-size-xs));
  @include buildTypographyUtility(sm, var(--font-size-sm));
  @include buildTypographyUtility(md, var(--font-size-md));
  @include buildTypographyUtility(lg, var(--font-size-lg));
  @include buildTypographyUtility(xl, var(--font-size-xl));
  @include buildTypographyUtility(base, var(--default-font-size));
}
